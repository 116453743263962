<template>
  <v-container
    id="search"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <div class="text-center">
      <v-dialog
        v-model="dialogpp"
        width="80%"
      >
        <v-card>
          <v-card-title class="text-h2 grey lighten-2">
            Política de privacidad
          </v-card-title>

          <v-card-text>
            <p class="text-center text-h4">
              Información en cumplimiento de la normativa de protección de datos personales
            </p>

            <p class="text-justify">
              En Europa y en España existen normas de protección de datos pensadas para proteger su información personal de obligado cumplimiento para nuestra entidad.
              Por ello, es muy importante para nosotros que entienda perfectamente qué vamos a hacer con los datos personales que le pedimos.
              Así, seremos transparentes y le daremos el control de sus datos, con un lenguaje sencillo y opciones claras que le permitirán decidir qué haremos con su información personal.
              Por favor, si una vez leída la presente información le queda alguna duda, no dude en preguntarnos.
              Muchas gracias por su colaboración.
            </p>

            <ul>
              <li>
                <strong>¿Quienes somos?</strong>
                <ul>
                  <li>Nuestra denominación: <strong>Relaciones Profesionales Estratégicas S.L</strong></li>
                  <li>Nuestro CIF / NIF: <strong>B87784153</strong></li>
                  <li>Nuestra actividad principal: <strong>Consultoría Estratégica</strong></li>
                  <li>Nuestra dirección: <strong>C/ Federico García Lorca 2, portal 6 1ºA, CP 28770, Colmenar Viejo (Madrid)</strong></li>
                  <li>Nuestro teléfono de contacto: <strong>672067772</strong></li>
                  <li>
                    Nuestra dirección de correo electrónico de contacto:
                    <strong>
                      <a
                        href="mailto:andres.garcia@bnimadridcentrosuroeste.com"
                        target="_blank"
                      >
                        andres.garcia@bnimadridcentrosuroeste.com
                      </a>
                    </strong>
                  </li>
                  <li>
                    Nuestra página web:
                    <strong>
                      <a
                        href="http://bnimadridcentrosuroeste.com"
                        target="_blank"
                      >
                        http://bnimadridcentrosuroeste.com
                      </a>
                    </strong>
                  </li>
                  <li>
                    Para su confianza y seguridad, le informamos que somos una entidad inscrita en el siguiente Registro Mercantil /Registro Público: <strong>Registro Mercantil de Madrid, Hoja nº M-640672, Folio 105, Tomo 35646.</strong>
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              Estamos a su disposición, no dude en contactar con nosotros.
            </p>
            <ul>
              <li>
                <strong>¿Para qué vamos a usar sus datos?</strong><br>
                Con carácter general, sus datos personales serán usados para poder relacionarnos con usted y poder prestarle nuestros servicios.<br>
                Asimismo, también pueden ser usados para otras actividades, como enviarle publicidad o promocionar nuestras actividades.<br>
              </li>
              <li>
                <strong>¿Por qué necesitamos usar sus datos?</strong><br>
                Sus datos personales son necesarios para poder relacionarnos con usted y poder prestarle nuestros servicios. En este sentido, pondremos a su disposición una serie de casillas que le permitirán decidir de manera clara y sencilla sobre el uso de su información personal.
              </li>
              <li>
                <strong>¿Quién va a conocer la información que le pedimos?</strong><br>
                Con carácter general, sólo el personal de nuestra entidad que esté debidamente autorizado podrá tener conocimiento de la información que le pedimos.<br>
                De igual modo, podrán tener conocimiento de su información personal aquellas entidades que necesiten tener acceso a la misma para que podamos prestarle nuestros servicios. Así por ejemplo, nuestro banco conocerá sus datos si el pago de nuestros servicios se realiza mediante tarjeta o transferencia bancaria.<br>
                Asimismo, tendrán conocimiento de su información aquellas entidades públicas o privadas a las cuales estemos obligados a facilitar sus datos personales con motivo del cumplimiento de alguna ley. Poniéndole un ejemplo, la Ley Tributaria obliga a facilitar a la Agencia Tributaria determinada información sobre operaciones económicas que superen una determinada cantidad.<br>
                En el caso de que, al margen de los supuestos comentados, necesitemos dar a conocer  su información personal a otras entidades, le solicitaremos previamente su permiso a través de opciones claras que le permitirán decidir a este respecto.
              </li>
              <li>
                <strong>¿Cómo vamos a proteger sus datos?</strong><br>
                Protegeremos sus datos con medidas de seguridad eficaces en función de los riesgos que conlleve el uso de su información.<br>
                Para ello, nuestra entidad ha aprobado una Política de Protección de Datos y se realizan controles y auditorías anuales para verificar que sus datos personales están seguros en todo momento.
              </li>
              <li>
                <strong>¿Enviaremos sus datos a otros países?</strong><br>
                En el mundo hay países que son seguros para sus datos y otros que no lo son tanto. Así por ejemplo, la Unión Europea es un entorno seguro para sus datos. Nuestra política es no enviar su información personal a ningún país que no sea seguro desde el punto de vista de la protección de sus datos.<br>
                En el caso de que, con motivo de prestarle el servicio, sea imprescindible enviar sus datos a un país que no sea tan seguro como España, siempre le solicitaremos previamente su permiso y aplicaremos medidas de seguridad eficaces que reduzcan los riesgos del envío de su información personal a otro país.
              </li>
              <li>
                <strong>¿Durante cuánto tiempo vamos a conservar sus datos?</strong><br>
                Conservaremos sus datos durante nuestra relación y mientras nos obliguen las leyes. Una vez finalizados los plazos legales aplicables, procederemos a eliminarlos de forma segura y respetuosa con el medio ambiente.
              </li>
              <li>
                <strong>¿Cuáles son sus derechos de protección de datos?</strong><br>
                En cualquier momento puede dirigirse a nosotros para saber qué información tenemos sobre usted, rectificarla si fuese incorrecta y eliminarla una vez finalizada nuestra relación, en el caso de que ello sea legalmente posible.<br>
                También tiene derecho a solicitar el traspaso de su información a otra entidad. Este derecho se llama “portabilidad” y puede ser útil en determinadas situaciones.<br>
                Para solicitar alguno de estos derechos, deberá realizar una solicitud escrita a nuestra dirección, junto con una fotocopia de su DNI, para poder identificarle.<br>
                En las oficinas de nuestra entidad disponemos de formularios específicos para solicitar dichos derechos y le ofrecemos nuestra ayuda para su cumplimentación.<br>
                Para saber más sobre sus derechos de protección de datos, puede consultar la  página web de la Agencia Española de Protección de Datos (www.agpd.es).
              </li>
              <li>
                <strong>¿Puede retirar su consentimiento si cambia de opinión en un momento posterior?</strong><br>
                Usted puede retirar su consentimiento si cambia de opinión sobre el uso de sus datos en cualquier momento.<br>
                Así por ejemplo, si usted en su día estuvo interesado/a en recibir publicidad de nuestros productos o servicios, pero ya no desea recibir más publicidad, puede hacérnoslo constar a través del formulario de oposición al tratamiento disponible en las oficinas de nuestra entidad.
              </li>
              <li>
                <strong>En caso de que entienda que sus derechos han sido desatendidos, ¿dónde puede formular una reclamación?</strong>
                En caso de que entienda que sus derechos han sido desatendidos por nuestra entidad, puede formular una reclamación en la Agencia Española de Protección de Datos, a través de alguno de los medios siguientes:
                <ul>
                  <li>Sede electrónica: www.agpd.es</li>
                  <li>Dirección postal: Agencia Española de Protección de Datos C/ Jorge Juan, 6 28001-Madrid</li>
                  <li>Vía telefónica: Telf. 901 100 099 - 91 266 35 17</li>
                </ul>
                <p>Formular una reclamación en la Agencia Española de Protección de Datos no conlleva ningún coste y no es necesaria la asistencia de abogado ni procurador.</p>
              </li>
              <li>
                <strong>¿Elaboraremos perfiles sobre usted?</strong><br>
                Nuestra política es no elaborar perfiles sobre los usuarios de nuestros servicios.<br>
                No obstante, pueden existir situaciones en las que, con fines de prestación del servicio, comerciales o de otro tipo, necesitemos elaborar perfiles de información sobre usted. Un ejemplo pudiera ser la utilización de su historial de compras o servicios para poder ofrecerle productos o servicios adaptados a sus gustos o necesidades.<br>
                En tal caso, aplicaremos medidas de seguridad eficaces que protejan su información en todo momento de personas no autorizadas que pretendan utilizarla en su propio beneficio.<br>
              </li>
              <li>
                <strong>¿Usaremos sus datos para otros fines?</strong><br>
                Nuestra política es no usar sus datos para otras finalidades distintas a las que le hemos explicado. Si, no obstante, necesitásemos usar sus datos para actividades distintas, siempre le solicitaremos previamente su permiso a través de opciones claras que le permitirán decidir al respecto.
              </li>
            </ul>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialogpp = false"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogcookie"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h3 grey lighten-2">
            INFORMACIÓN BÁSICA SOBRE COOKIES
          </v-card-title>

          <v-card-text>
            <p class="text-justify">
              Una cookie o galleta informática es un pequeño archivo de información que se guarda en tu ordenador, "smartphone" o tableta cada vez que visitas nuestra página web.
              Nuestra página web únicamente utiliza cookies técnicas, que son imprescindibles para que la página pueda funcionar. Las tenemos activadas por defecto, pues no necesitan de tu autorización.
              Más información en nuestro apartado POLÍTICA DE COOKIES.
            </p>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialogcookie = false"
            >
              ACEPTAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogpc"
        width="50%"
      >
        <v-card>
          <v-card-title class="text-h3 grey lighten-2">
            POLÍTICA DE COOKIES
          </v-card-title>

          <v-card-text>
            <p class="text-justify">
              Bienvenida/o a la POLÍTICA DE COOKIES de la página web de la entidad Relaciones Profesionales Estratégicas S.L, provista de NIF B87784153, donde te explicaremos en un lenguaje claro y sencillo todas las cuestiones necesarias para que puedas tener el control sobre ellas en base a tus decisiones personales.
            </p>
            <p class="font-weight-medium">
              ¿QUÉ SON LAS COOKIES Y PARA QUÉ LAS USAMOS?
            </p>
            <p class="text-justify">
              Una cookie o galleta informática es un pequeño archivo de información que se guarda en tu ordenador, “smartphone” o tableta cada vez que visitas nuestra página web.<br>
              En principio, una cookie es inofensiva: no contiene virus, troyanos, gusanos, etc. que puedan dañar tu terminal, pero sí tiene cierto impacto sobre tu derecho a la protección de tus datos personales, pues recoge determinada información concerniente a tu persona (hábitos de navegación, identidad, preferencias, etc.).<br>
              Nuestra página web únicamente utiliza cookies técnicas, las mismas son estrictamente necesarias para que nuestra página web funcione y puedas navegar por la misma. Este tipo de cookies son las que, por ejemplo, nos permiten identificarte, darte acceso a determinadas partes restringidas de la página si fuese necesario, o recordar diferentes opciones o servicios ya seleccionados por ti, como tus preferencias de privacidad. Por ello, están activadas por defecto, no siendo necesaria tu autorización al respecto.<br>
              A través de la configuración de tu navegador, puedes bloquear o alertar de la presencia de este tipo de cookies, si bien dicho bloqueo afectará al correcto funcionamiento de las distintas funcionalidades de nuestra página web.
            </p>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialogpc = false"
            >
              ACEPTAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-row justify="center">
      <v-col
        cols="12"
        md="auto"
      >
        <v-img
          :width="300"
          src="@/assets/logo.png"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <v-stepper
          v-model="e1"
          elevation="8"
          non-linear
        >
          <v-stepper-header>
            <v-stepper-step
              :complete="e1 > 1"
              step="1"
              :rules="[() => !!stepValid1]"
            >
              Grupo BNI
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :complete="e1 > 2"
              step="2"
              :rules="[() => !!stepValid2]"
            >
              Tus datos personales
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :complete="e1 > 3"
              step="3"
              :rules="[() => !!stepValid3]"
            >
              Datos de facturación
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :complete="e1 > 4"
              step="4"
              :rules="[() => !!stepValid4]"
            >
              Networking
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              step="5"
              :rules="[() => !!stepValid5]"
            >
              Compromisos
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card
                class="mb-12"
                elevation="2"
                color="grey lighten-4"
              >
                <v-row>
                  <v-col
                    class="mx-auto"
                    cols="11"
                    md="11"
                  >
                    <v-form
                      ref="stepForm1"
                      v-model="stepValid1"
                      lazy-validation
                    >
                      <v-radio-group
                        v-model="esRenovacion"
                        mandatory
                        row
                      >
                        <v-radio
                          label="ALTA NUEVA"
                          :value="false"
                        />
                        <v-radio
                          label="RENOVACIÓN"
                          :value="true"
                        />
                      </v-radio-group>
                      <v-select
                        v-model="memberItem.grupo"
                        class="mt-3"
                        outlined
                        :rules="campoRules"
                        :items="grupoBNI"
                        item-text="grupo"
                        item-value="id"
                        label="GRUPO BNI"
                        placeholder="GRUPO BNI"
                      />
                      <v-text-field
                        v-if="esRenovacion"
                        v-model="memberItem.mail"
                        outlined
                        :rules="emailRules"
                        label="EMAIL"
                        placeholder="EMAIL"
                        hint="Introduce por favor el correo con el que te diste de alta en BNI Connect para poder acceder a tus datos."
                      />
                    </v-form>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                color="primary"
                :disabled="!stepValid1"
                @click="validate1"
              >
                Siguiente
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card
                class="mb-12"
                elevation="2"
                color="grey lighten-4"
              >
                <v-row>
                  <v-col
                    class="mx-auto"
                    cols="11"
                    md="11"
                  >
                    <v-form
                      ref="stepForm2"
                      v-model="stepValid2"
                      lazy-validation
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="memberItem.nombre"
                            outlined
                            :rules="campoRules"
                            label="NOMBRE"
                            placeholder="NOMBRE"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="memberItem.apellido"
                            outlined
                            :rules="campoRules"
                            label="APELLIDOS"
                            placeholder="APELLIDOS"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="memberItem.mail"
                            :disabled="esRenovacion"
                            outlined
                            :rules="emailRules"
                            label="EMAIL"
                            placeholder="EMAIL"
                            hint="Introduce por favor el correo con el que te diste de alta en BNI Connect para poder acceder a tus datos."
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="memberItem.telefono"
                            outlined
                            :rules="telefonoRules"
                            label="TELÉFONO"
                            placeholder="TELÉFONO"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="memberItem.especialidad"
                            outlined
                            :rules="campoRules"
                            label="ESPECIALIDAD PROFESIONAL"
                            placeholder="ESPECIALIDAD PROFESIONAL"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="memberItem.invito"
                            outlined
                            :rules="campoRules"
                            label="NOMBRE Y APELLIDO DE QUIEN TE INVITÓ"
                            placeholder="NOMBRE Y APELLIDO DE QUIEN TE INVITÓ"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                @click="e1 = 1"
              >
                Atrás
              </v-btn>

              <v-btn
                color="primary"
                :disabled="!stepValid2"
                @click="validate2"
              >
                Siguiente
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card
                class="mb-12"
                elevation="2"
                color="grey lighten-4"
              >
                <v-row>
                  <v-col
                    class="mx-auto"
                    cols="11"
                    md="11"
                  >
                    <v-form
                      ref="stepForm3"
                      v-model="stepValid3"
                      lazy-validation
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="memberItem.razon"
                            outlined
                            :rules="campoRules"
                            label="RAZON SOCIAL / NOMBRE FISCAL"
                            placeholder="RAZON SOCIAL / NOMBRE FISCAL"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="memberItem.cif"
                            outlined
                            :rules="campoRules"
                            label="NIF / CIF"
                            placeholder="NIF / CIF"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="memberItem.domicilio"
                            outlined
                            :rules="campoRules"
                            label="DIRECCIÓN FISCAL"
                            placeholder="DIRECCIÓN FISCAL"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="memberItem.poblacion"
                            outlined
                            :rules="campoRules"
                            label="POBLACIÓN"
                            placeholder="POBLACIÓN"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="memberItem.provincia"
                            outlined
                            :rules="campoRules"
                            label="PROVINCIA"
                            placeholder="PROVINCIA"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="memberItem.postal"
                            outlined
                            :rules="postalRules"
                            label="CÓDIGO POSTAL"
                            placeholder="CÓDIGO POSTAL"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                @click="e1 = 2"
              >
                Atrás
              </v-btn>

              <v-btn
                color="primary"
                :disabled="!stepValid3"
                @click="validate3"
              >
                Siguiente
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-card
                class="mb-12"
                elevation="2"
                color="grey lighten-4"
              >
                <v-row>
                  <v-col
                    class="mx-auto"
                    cols="11"
                    md="11"
                  >
                    <v-form
                      ref="stepForm4"
                      v-model="stepValid4"
                      lazy-validation
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="memberItem.miembroBNI"
                            outlined
                            :items="grupo"
                            :rules="campoRules"
                            item-text="text"
                            item-value="value"
                            label="¿Ha sido miembro de BNI anteriormente?"
                            placeholder="¿Ha sido miembro de BNI anteriormente?"
                            hint="¿Ha sido miembro de BNI anteriormente?"
                            @change="validateGrupoBNIAnt()"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="memberItem.grupoBNIAnt"
                            outlined
                            :rules="grupoBNIAntRules"
                            label="En caso afirmativo, ¿cuándo y en qué grupo?"
                            placeholder="En caso afirmativo, ¿cuándo y en qué grupo?"
                            hint="En caso afirmativo, ¿cuándo y en qué grupo?"
                            @input="validateGrupoBNIAnt()"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="memberItem.miembroOrgQ"
                            outlined
                            :items="grupo"
                            :rules="campoRules"
                            item-text="text"
                            item-value="value"
                            label="¿Es miembro actualmente de alguna otra organización de networking cuyo objetivo sea también el intercambio de referencias?"
                            placeholder="¿Es miembro actualmente de alguna otra organización de networking cuyo objetivo sea también el intercambio de referencias?"
                            hint="¿Es miembro actualmente de alguna otra organización de networking cuyo objetivo sea también el intercambio de referencias?"
                            @change="validateMiembroOrgA()"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="memberItem.miembroOrgA"
                            outlined
                            :rules="miembroOrgARules"
                            label="En caso afirmativo, ¿a cuál?"
                            placeholder="En caso afirmativo, ¿a cuál?"
                            hint="En caso afirmativo, ¿a cuál?"
                            @input="validateMiembroOrgA()"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                @click="e1 = 3"
              >
                Atrás
              </v-btn>

              <v-btn
                color="primary"
                :disabled="!stepValid4"
                @click="validate4"
              >
                Siguiente
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="5">
              <v-card
                class="mb-12"
                elevation="2"
                color="grey lighten-4"
              >
                <v-row>
                  <v-col
                    class="mx-auto"
                    cols="11"
                    md="11"
                  >
                    <v-form
                      ref="stepForm5"
                      v-model="stepValid5"
                      lazy-validation
                    >
                      <p>
                        Certifico que toda la información contenida en este formulario es veraz y que, de lo contrario, puede suponer la terminación de mi participación en el Grupo a discreción de BNI.
                        También entiendo que, una vez aceptada mi candidatura, las cuotas no son reembolsables.<b> (Firmar en el campo táctil o con el ratón)</b>
                      </p>
                      <v-dialog
                        v-model="dialog"
                        persistent
                        scrollable
                        max-width="800"
                      >
                        <v-card>
                          <v-card-text>
                            <br>
                            <h2 class="text-center">
                              <b>Código de Ética</b>
                            </h2>
                            1. Proveeré servicios de calidad al precio presupuestado.<br>
                            2. Seré sincero con los miembros y sus referencias.<br>
                            3. Fomentaré la buena voluntad y la confianza entre los miembros y sus referencias.<br>
                            4. Me comprometo a hacer seguimiento de las referencias recibidas.<br>
                            5. Mostraré una actitud positiva y solidaria.<br>
                            <br>
                            <h2 class="text-center">
                              <b>Políticas Generales BNI</b>
                            </h2>
                            El Comité de Miembros de cada Grupo tiene la autoridad final con respecto a las Políticas de BNI®. El Comité de Miembros puede asignar a un Miembro de BNI® a un Período de Apoyo o declarar abierta la categoría profesional de un Miembro en caso de incumplimiento de las Políticas y/o del Código de Ética o Valores Fundamentales de BNI®.<br>
                            1. Sólo puede unirse a un grupo de BNI® una persona por cada categoría profesional, y cada Miembro solo puede representar una categoría en su grupo de BNI®.<br>
                            2. Los Miembros de BNI® deben representar su actividad principal.<br>
                            3. Los Miembros de BNI® han de llegar puntuales y quedarse durante toda la reunión.<br>
                            4. Una persona solo puede ser Miembro de un Grupo de BNI®. Un Miembro no debe participar en ningún otro programa cuyo propósito principal sea el intercambio de referencias.<br>
                            5. Un Miembro de BNI® tiene permitidas tres ausencias dentro de un período continuo de seis meses. Si un Miembro no puede asistir, puede enviar un sustituto; esto no contará como ausencia.<br>
                            6. Se espera que los Miembros se comprometan con su Grupo de BNI® aportando referencias cualificadas y/o Invitados.<br>
                            7. Los Invitados pueden asistir a las reuniones de BNI® hasta en dos ocasiones.<br>
                            8. Solo los Miembros de BNI® que hayan completado el “Programa de Éxito para Miembros”, Directores y Directores Consultores pueden hacer las presentaciones durante las reuniones de BNI®.<br>
                            9. Los permisos de ausencia son posibles para ciertas circunstancias atenuantes (por ejemplo, un problema médico prolongado que impide que el miembro trabaje) a discreción del Comité de Miembros.<br>
                            10. Los Miembros que deseen cambiar su categoría en BNI® deben presentar una nueva candidatura al Comité de Miembros para su aprobación.<br>
                            11. Cualquier listado de Miembros de BNI® tiene el único propósito de generar referencias. Antes de enviar cualquier comunicación comercial o de marketing a los Miembros de BNI® que no sean de tu Grupo, o a Directores, el destinatario debe dar su consentimiento. El consentimiento debe ser libre, específico, informado y sin ambigüedades.<br>
                            Las políticas están sujetas a cambios. Cualquier propuesta de cambio en las políticas ha de ser revisada previamente por la Junta Internacional de Consejeros.<br>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              text
                              @click="dialog = false"
                            >
                              Cerrar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-checkbox
                        v-model="checkbox"
                        :rules="[v => !!v || 'No olvides leer y aceptar las Políticas']"
                        required
                      >
                        <template v-slot:label>
                          <div>
                            Acepto y me comprometo a seguir la
                            <a
                              href="#"
                              @click="dialog = true"
                            >
                              Política General y el Código de Ética de BNI
                            </a>
                          </div>
                        </template>
                      </v-checkbox>
                      <v-checkbox
                        :rules="[v => !!v || 'Debes leer y aceptar las políticas de privacidad']"
                        required
                      >
                        <template v-slot:label>
                          <div>
                            Consiento el uso de mis datos para los fines indicados en la
                            <a
                              href="#"
                              @click="dialogpp = true"
                            >
                              política de privacidad
                            </a>
                          </div>
                        </template>
                      </v-checkbox>
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <VueSignaturePad
                            ref="signaturePad"
                            width="60%"
                            height="200px"
                            :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}"
                            :custom-style="estilacho"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                          class="text-center"
                        >
                          <v-btn
                            color="red"
                            class="mr-2"
                            @click="undo"
                          >
                            Deshacer firma
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                @click="e1 = 4"
              >
                Atrás
              </v-btn>
              <v-btn
                color="primary"
                :disabled="!stepValid5"
                @click="validate5"
              >
                Siguiente
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <!-- <v-row justify="center">
      <v-col
        class="text-center mb-sm-0 mb-5"
        cols="auto"
      >
        <p class="mb-2">
          Si ya eres un miembro registado y necesitas renovar tu membresía. Haz clic aquí
        </p>
        <v-btn
          color="primary"
          @click="irRenovaciones()"
        >
          Renovar membresía
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row justify="center">
      <v-col
        class="text-center mb-sm-0 mb-5"
        cols="auto"
      >
        <a
          href="#"
          class="mr-0 grey--text text--darken-3"
          rel="noopener"
          @click="dialogpp = true"
        >
          Política de privacidad
        </a>
      </v-col>
      <v-col
        class="text-center mb-sm-0 mb-5"
        cols="auto"
      >
        <a
          href="#"
          class="mr-0 grey--text text--darken-3"
          rel="noopener"
          @click="dialogpc = true"
        >
          Política de cookies
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import GruposApi from '@/services/api/Grupos'
  import MembersApi from '@/services/api/Miembros'

  export default {
    name: 'RegisterMember',
    data: () => ({
      e1: 1,
      dialog: false,
      dialogpp: false,
      dialogcookie: true,
      dialogpc: false,
      checkbox: false,
      valid: true,
      lazy: false,
      snackbar: false,
      colorSnak: 'info',
      text: '',
      timeout: 3000,
      showSignature: true,
      estilacho: {
        border: 'black 3px solid',
        'margin-right': 'auto',
        'margin-left': 'auto',
      },
      stepValid1: true,
      stepValid2: true,
      stepValid3: true,
      stepValid4: true,
      stepValid5: true,
      miembroANterior: true,
      stepForm: [
        'grupo',
      ],
      memberItem: {
        grupo: '',
        nombre: '',
        apellido: '',
        mail: '',
        telefono: null,
        especialidad: '',
        invito: '',
        razon: '',
        cif: '',
        domicilio: '',
        poblacion: '',
        provincia: '',
        postal: null,
        miembroBNI: '',
        grupoBNIAnt: '',
        miembroOrgQ: '',
        miembroOrgA: '',
        esInvitacion: false,
        estado: '',
      },
      grupo: [
        { value: 'SI', text: 'SI' },
        { value: 'NO', text: 'NO' },
      ],
      esRenovacion: null,
      grupoBNI: [],
      dniRules: [
        v => {
          var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET'
          var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i
          var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i

          if (v !== undefined) {
            var str = v.toString().toUpperCase()
          }

          if (str === '') {
            return 'Completa este campo, por favor'
          }
          var isNIF = nifRexp.test(str)
          var isNIE = nieRexp.test(str)
          if (!isNIF && !isNIE) {
            return 'Introduce un CIF/NIF correcto'
          }
          var nie = str
            .replace(/^[X]/, '0')
            .replace(/^[Y]/, '1')
            .replace(/^[Z]/, '2')
          var letter = str.substr(-1)
          var charIndex = parseInt(nie.substr(0, 8)) % 23
          if (validChars.charAt(charIndex) === letter) {
            return true
          }
          return 'Debe ingresar un DNI/NIF/NIE correcto'
        },
      ],
      nameRules: [
        v => !!v || 'Completa este campo, por favor',
      ],
      apeRules: [
        v => !!v || 'Completa este campo, por favor',
      ],
      emailRules: [
        v => !!v || 'Completa este campo, por favor',
        v => /.+@.+\..+/.test(v) || 'Ingresa un correo electrónico correcto',
      ],
      telefonoRules: [
        v => !!v || 'Completa este campo, por favor',
        v => /^[0-9]{9}$/.test(v) || 'Ingrese un número telefónico correcto',
      ],
      postalRules: [
        v => !!v || 'Completa este campo, por favor',
        v => /^[0-9]{4,5}$/.test(v) || 'Ingrese un código postal correcto',
      ],
      ciudadRules: [
        v => !!v || 'Completa este campo, por favor',
      ],
      campoRules: [
        v => !!v || 'Completa este campo, por favor',
      ],
      grupoBNIAntRules: [true],
      miembroOrgARules: [true],
    }),

    created () {
      // Hay token
      if (undefined !== this.$route.params.token) {
        this.fetchMiembro(this.$route.params.token)
      }
      this.initialize()
    },

    methods: {
      initialize () {
        this.fetchGrupos()
      },
      fetchMiembro (token) {
        MembersApi.getMiembroByToken(token)
          .then(miembro => {
            if (miembro.estado === 'SUSCRITO' || parseInt(miembro.eliminado) === 1) {
              this.$router.push({ name: 'Member Register' })
            } else {
              // El usuario invitado que presione el botón de Siguiente, se considerará ya luego como registrado
              miembro.esInvitacion = false
              this.memberItem = Object.assign({}, miembro)
            }
          })
          .catch(error => {
            console.log(error)
            this.$router.push({ name: 'Member Register' })
          })
      },
      fetchGrupos () {
        GruposApi.getGruposActivosNoRenovaciones()
          .then(grupo => {
            this.grupoBNI = grupo
          })
          .catch(error => console.log(error))
      },
      undo () {
        this.$refs.signaturePad.undoSignature()
      },
      validate1 () {
        this.stepValid1 = false
        const v = this.$refs.stepForm1.validate()
        if (v && !this.esRenovacion) {
          this.stepValid1 = true
          this.e1 = 2
        }
        if (v && this.esRenovacion) {
          MembersApi.getMiembroByEmail(this.memberItem.mail)
            .then(miembro => {
              if (parseInt(miembro.eliminado) === 1) {
                this.text = 'Su usuario ha sido inhabilitado. Puede ponerse en contacto si considera que esto ha sido un error.'
                this.colorSnak = 'error'
                this.snackbar = true
              } else if (typeof this.memberItem.grupo === 'number' && (miembro.grupo.id !== this.memberItem.grupo)) {
                this.text = 'Usted no es miembro del grupo que ha seleccionado.'
                this.colorSnak = 'error'
                this.snackbar = true
              } else if (typeof this.memberItem.grupo !== 'number' && (miembro.grupo.id !== this.memberItem.grupo.id)) {
                this.text = 'Usted no es miembro del grupo que ha seleccionado.'
                this.colorSnak = 'error'
                this.snackbar = true
              } else {
                const grupoSeleccionado = this.memberItem.grupo
                this.memberItem = Object.assign({}, miembro)
                this.memberItem.grupo = grupoSeleccionado
                this.memberItem.esInvitacion = false
                this.stepValid1 = true
                this.e1 = 2
              }
            })
            .catch(error => {
              console.log(error)
              this.text = 'No hemos encontrado su dirección de correo en nuestros registros.'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },
      validate2 () {
        this.stepValid2 = false
        const v = this.$refs.stepForm2.validate()
        if (v) {
          if (undefined === this.$route.params.token && !this.esRenovacion) {
            MembersApi.buscarEmailDuplicado(this.memberItem.mail)
              .then(response => {
                this.stepValid2 = true
                this.e1 = 3
              })
              .catch(error => {
                console.log(error)
                this.text = 'Esta dirección de correo ya existe. Si necesita renovar membresía, presione el botón Atrás y marque esa opción.'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          } else {
            this.stepValid2 = true
            this.e1 = 3
          }
        }
      },
      validate3 () {
        this.stepValid3 = false
        const v = this.$refs.stepForm3.validate()
        if (v) {
          this.stepValid3 = true
          this.e1 = 4
        }
      },
      validate4 () {
        this.stepValid4 = false
        const v = this.$refs.stepForm4.validate()
        if (v) {
          this.stepValid4 = true
          this.e1 = 5
        }
      },
      validate5 () {
        this.stepValid5 = false
        const v = this.$refs.stepForm5.validate()
        if (v) {
          this.stepValid5 = true
          if (undefined !== this.$route.params.token && !this.esRenovacion) {
            MembersApi.update(this.memberItem.id, this.memberItem)
              .then(response => {
                this.$router.push({ name: 'memberResume', params: { token: response.suscripcion } })
              })
              .catch(error => {
                console.log(error)
                this.text = 'No ha sido posible completar el registro. Es posible que algo no esté bien con sus datos'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          } else if (this.esRenovacion) {
            // Se agrega acá este valor, solo con el fin de envío al backend. No aplica en el registro de miembro
            this.memberItem.esRenovacion = true
            MembersApi.addRenewal(this.memberItem)
              .then(response => {
                this.$router.push({ name: 'memberResume', params: { token: response.suscripcion } })
              })
              .catch(error => {
                console.log(error)
                this.text = 'No ha sido posible completar el registro. Es posible que algo no esté bien con sus datos'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          } else {
            MembersApi.add(this.memberItem)
              .then(response => {
                this.$router.push({ name: 'memberResume', params: { token: response.suscripcion } })
              })
              .catch(error => {
                console.log(error)
                this.text = 'No ha sido posible completar el registro. Es posible que algo no esté bien con sus datos'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          }
        }
      },
      validateGrupoBNIAnt () {
        if (this.memberItem.miembroBNI === 'SI' && this.memberItem.grupoBNIAnt === '') {
          this.grupoBNIAntRules = ['Completa este campo, por favor']
        } else {
          this.grupoBNIAntRules = [true]
        }
      },
      validateMiembroOrgA () {
        if (this.memberItem.miembroOrgQ === 'SI' && this.memberItem.miembroOrgA === '') {
          this.miembroOrgARules = ['Completa este campo, por favor']
        } else {
          this.miembroOrgARules = [true]
        }
      },
      irRenovaciones () {
        this.$router.push({ name: 'Member Renewal' })
      },
    },
  }
</script>
